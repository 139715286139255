<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" title="设置网银打款密码" width="400px">
      <el-form ref="refund" :model="refundParam" :rules="rules" size="mini" label-width="90px">
        <el-form-item label="短信验证码" prop="verifyCode">
          <el-input v-model="refundParam.verifyCode" placeholder="请输入短信验证码" show-word-limit clearable
            :style="{width: '100%'}">
            <template slot="append">
                <el-button @click="sendVerifyCode" :disabled="verifyCodeDisable">{{verifyCodeText}}</el-button>
                </template>
          </el-input>
        </el-form-item>
        <el-form-item label="打款密码" prop="password">
          <el-input v-model="refundParam.password" placeholder="请输入6数字的打款密码" clearable show-password
            :style="{width: '100%'}"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handelConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { sendRemitPasswordCode, setRemitPassword } from "@/api/user.js"

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
        verifyCodeText: "发送验证码",
        verifyCodeDisable: false,
      refundParam: {
        verifyCode: undefined,
        password: undefined,
      },
      rules: {
        verifyCode: [{
          required: true,
          message: '请输入短信验证码',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入6数字的打款密码',
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    sendVerifyCode(){
        sendRemitPasswordCode({}).then(res => {
            if(res.code === 200) {
                this.$message.success(res.data)
                var second = 60;
                var interval = setInterval(()=>{
                    if(second <= 0) {
                        clearInterval(interval)
                        this.verifyCodeText = "发送验证码";
                        this.verifyCodeDisable = false;
                    } else {
                        second -= 1
                        this.verifyCodeText = "剩余(" + second + ")秒";
                        this.verifyCodeDisable = true;
                    }
                }, 1000)
            } else {
                this.$message.error(res.message)
            }
        })
    },
    onOpen() {},
    onClose() {
      this.$refs['refund'].resetFields()
    },
    close() {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.$refs['refund'].validate(valid => {
        if (!valid) return
        setRemitPassword(this.refundParam).then(res => {
            if(res.code === 200) {
                this.$message.success(res.message)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        })
      })
    },
  }
}

</script>
<style>
</style>